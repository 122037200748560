import { postRequest, formDataRequest } from "../libs/AxiosService.util";

export async function serviceCreateSalesReport(params) {
  return await formDataRequest("cr/budget/edit/versions/create", params);
}

export async function serviceUpdateSalesReport(params) {
  return await postRequest("cr/update", params);
}

export async function serviceDeleteSalesReport(params) {
  return await postRequest("cr/delete", params);
}

export async function serviceGetSalesReport(params) {
  return await postRequest("sales/target/employee", params);
}
export async function serviceGetSalesReportStatus(params) {
  return await postRequest("sales/target/status", params);
}
export async function serviceSalesReportDetail(params) {
  return await postRequest("cr/budget/detail", params);
}
export async function serviceSalesReportSend(params) {
  return await postRequest("sales/target/send", params);
}
export async function serviceSalesReportCPCImportVerify(params) {
  return await formDataRequest("sales/target/import/verify", params);
}
export async function serviceSalesReportCPCImportFile(params) {
  return await formDataRequest("sales/target/import", params);
}
export async function serviceExportSalesReport(params) {
  return await postRequest('sales/target/employee/export', params);
}