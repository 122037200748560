import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    ButtonBase,
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Slide,
    Divider,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import Styles from "./Style.module.css";

const useStyles = makeStyles((theme) => ({
    closeBtn: {
        // position: "absolute",
        // right: theme.spacing(2),
        // top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogTitle: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: theme.spacing(0),
    },
    divider: {
        margin: theme.spacing(1, 0),
    },
    listItem: {
        padding: theme.spacing(0),
        cursor: "pointer"
    },
    dialogContent: {
        padding: theme.spacing(0),
        maxHeight: "400px",  
        overflowY: "auto",
    },
    button: {
        marginTop: theme.spacing(2),
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const OverlappingEventsDialog = ({ open, onClose, events, handleEventClick }) => {
    const classes = useStyles();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            TransitionComponent={Transition}
            BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0.3)" },
            }}
        >
            <div className={Styles.resetPasswordWrapper}>

                <div className={Styles.containerHead}>
                    <div className={Styles.upperWrap}>
                        <div className={Styles.title}>
                            Interview list
                            {/* <div className={Styles.newLine} /> */}
                        </div>
                        <ButtonBase className={classes.closeBtn} onClick={onClose}>
                            <Close />
                        </ButtonBase>
                    </div>
                </div>
                <DialogContent className={classes.dialogContent}>
                    <div>
                        {events?.map((event, index) => (
                            <div key={index} className={classes.listItem}
                                onClick={() => {
                                    handleEventClick(event)
                                    onClose()
                                }}>
                                <ListItemText
                                    primary={
                                        <span>
                                            {event.fullName}
                                        </span>
                                    }
                                    className={{ root: classes.listItem }}
                                    secondary={`Start: ${moment(event.start).format(
                                        "MMM D, h:mm A"
                                    )} | End: ${moment(event.end).format("MMM D, h:mm A")}`}
                                />
                                {index < events.length - 1 && <Divider classes={{ root: classes.divider }} />}
                            </div>
                        ))}


                    </div>
                </DialogContent>
            </div>

        </Dialog>
    );
};

export default OverlappingEventsDialog;
