import React, { Component, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Paper,
  Checkbox,
  IconButton,
  MenuItem,
  ButtonBase,
} from "@material-ui/core";
import classNames from "classnames";
import { connect, useSelector } from "react-redux";
import { Add, InfoOutlined, PrintOutlined } from "@material-ui/icons";
import PageBox from "../../../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import DataTables from "../../../../Datatables/Datatable.table";
import Constants from "../../../../config/constants";
import StatusPill from "../../../../components/Status/StatusPill.component";
import useProvisionalTaxFormHook from "./ProvisionalTaxForm.hook";
import FilterComponent from "../../../../components/Filter/Filter.component";
const Roles = Constants.ROLES;

const ProvisionalTaxFormListContainer = ({}) => {
  const {
    handleSortOrderChange,
    handleRowSize,
    handlePageChange,
    handleViewDetails,
    isCalling,
    configFilter,
    handleFilterDataChange,
    handleSearchValueChange,
  } = useProvisionalTaxFormHook({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.provisional_tax);

  const renderStatus = useCallback((status) => {
    if (status === "NEW_TAX_REGIME") {
      return (
        <StatusPill
          style={{
            color: "#182C40",
            whiteSpace: "nowrap",
            border: "1px solid #182C40",
          }}
          status={status.replace(/_/g, " ")}
        />
      );
    } else {
      return <StatusPill status={status.replace(/_/g, " ")} />;
    }
  }, []);
  const renderDate = useCallback((value) => {
    if (value?.claim_type === "MARRAIGE") {
      return <div>{value?.domText}</div>;
    } else if (value?.claim_type === "PHC") {
      return <div>{value?.phcDateText}</div>;
    } else {
      return <div>{value?.billDateText}</div>;
    }
  });
  const removeUnderScore = (value) => {
    return value ? value.replace(/_/g, " ") : "";
  };
  const renderFirstCell = useCallback((obj) => {
    if (obj) {
      return (
        <div className={styles.firstCellFlex}>
          <div className={classNames(styles.firstCellInfo, "openSans")}>
            <span className={styles.productName}>
              <span>{obj?.name}</span>
            </span>
            <br />
            <span>{obj?.emp_code}</span>
          </div>
        </div>
      );
    }
    return null;
  }, []);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "type",
        label: "EMPLOYEE NAME",
        render: (value, all) => <div>{renderFirstCell(all?.employee)}</div>,
      },
      {
        key: "date",
        label: "GRADE/CADRE",
        render: (temp, all) => (
          <div>{`${all?.employee?.grade?.name} / ${all?.employee?.cadre?.name}`}</div>
        ),
      },
      {
        key: "date",
        label: "LOCATION",
        render: (temp, all) => <div>{all?.employee?.location?.name}</div>,
      },
      {
        key: "date",
        label: "DESIGNATION",
        render: (temp, all) => <div>{all?.employee?.designation?.name}</div>,
      },
      {
        key: "date",
        label: "DEPT. / SUB DEPT.",
        render: (temp, all) => (
          <div>{`${all?.employee?.department?.name} / ${all?.employee?.sub_department?.name}`}</div>
        ),
      },
      {
        key: "type",
        label: "FORM TYPE",
        render: (temp, all) => <div>{all?.type}</div>,
      },
      {
        key: "as_year",
        label: "AY 2025-2026",
        render: (temp, all) => <div>{all?.as_year}</div>,
      },
      {
        key: "submittedOnText",
        label: "SUBMITTED ON",
        render: (temp, all) => <div>{all?.submittedOnText}</div>,
      },
      {
        key: "tax_payable_refundable",
        label: "TAX AMOUNT",
        render: (temp, all) => <div>{`₹${all?.tax_payable_refundable}`}</div>,
      },
      {
        key: "tax_regime",
        label: "TAX REGIME",
        render: (temp, all) => <div>{renderStatus(all?.tax_regime)}</div>,
      },
      {
        key: "status",
        label: "STATUS",
        render: (temp, all) => <div>{renderStatus(all.status)}</div>,
      },
      {
        key: "user_id",
        label: "Action",
        ishideMobile: true,
        render: (temp, all) => (
          <div>
            <IconButton
              className={"tableActionBtn"}
              color="primary"
              disabled={isCalling}
              onClick={() => {
                handleViewDetails(all);
              }}
            >
              <InfoOutlined fontSize={"small"} style={{ color: "#2896E9" }} />
            </IconButton>
          </div>
        ),
      },
    ];
  }, [
    renderStatus,
    renderFirstCell,
    handleViewDetails,
    isCalling,
    handleViewDetails,
  ]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>
              Provisional Tax Record Approval
            </span>
            <div className={styles.newLine} />
          </div>
        </div>
        <div>
          <FilterComponent
            is_progress={isFetching}
            filters={configFilter}
            handleSearchValueChange={handleSearchValueChange}
            handleFilterDataChange={handleFilterDataChange}
            placeholder="Search Job Title/Code"
          />
          <div>
            <div style={{ width: "100%" }}>
              <DataTables
                {...tableData.datatable}
                {...tableData.datatableFunctions}
              />
            </div>
          </div>
        </div>
      </PageBox>
    </div>
  );
};

export default ProvisionalTaxFormListContainer;
