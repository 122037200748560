import { useCallback, useEffect, useState } from "react";
import { serviceGetInterviewDetail } from "../../../../../services/InterviewSchedule.service";
import history from "../../../../../libs/history.utils";
import RouteName from "../../../../../routes/Route.name";

const useInterviewDetailDialogHook = ({ eventId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [interviewDetail, setInterviewDetail] = useState({});

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);
      serviceGetInterviewDetail({ interview_id: eventId }).then((res) => {
        if (!res?.error) {
          setInterviewDetail(res.data);
          setIsLoading(false);
        }
      });
    }
  }, [eventId]);

  const handleJobDetails = useCallback((data)=>{
    history.push(`${RouteName.JOB_OPENINGS_DETAILS}${data?.id}`)
  },[])
  
  const handleCandidateDetails = useCallback((data)=>{
    history.push(`${RouteName.CANDIDATES_DETAILS}${data?.id}`)
  },[])

  return {
    isLoading,
    interviewDetail,
    handleJobDetails,
    handleCandidateDetails
  };
};

export default useInterviewDetailDialogHook;
