import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  actionFetchSalesReport,
  actionSetPageSalesReportRequests,
} from "../../actions/SalesReport.action";
import { serviceGetList } from "../../services/Common.service";
import { serviceExportSalesReport } from "../../services/SalesReport.service";
import SnackbarUtils from "../../libs/SnackbarUtils";

const initialForm = {
  location_id: "",
  department_id: "66600f565a24d986078325a0",
  fy_year: "",
  quarter: "Q1",
};

const useSalesReport = ({}) => {
  const [form, setForm] = useState({ ...initialForm });
  const [errorData, setErrorData] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusEnable, setStatusEnable] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isSidePanel, setSidePanel] = useState(false);
  const [listData, setListData] = useState({
    LOCATIONS: [],
    DEPARTMENTS: [],
    FY_YEAR: [],
    SUB_DEPARTMENTS: [],
  });
  const dispatch = useDispatch();
  const isMountRef = useRef(false);

  useEffect(() => {
    serviceGetList([
      "LOCATIONS",
      "FY_YEAR",
      "DEPARTMENTS",
      "SUB_DEPARTMENTS",
    ]).then((res) => {
      if (!res.error) {
        setListData(res.data);
      }
    });
  }, []);

  const filteredSubDepartments = useMemo(() => {
    return listData?.SUB_DEPARTMENTS?.filter(
      (val) => val.department_id === "66600f565a24d986078325a0"
    );
  }, [listData]);

  const {
    sorting_data: sortingData,
    is_fetching: isFetching,
    query,
    query_data: queryData,
  } = useSelector((state) => state.sales_report);

  const enableUploader = useMemo(() => {
    return Object.values(form)?.every((value) => value !== "");
  }, [form]);

  const hq = queryData?.filter((s) => s?.name === "sub_department_id") || [];
  const filteredHq = hq.flatMap(
    (val) => val?.value?.map((s) => s?.label) || []
  );


  const resetData = useCallback(
    (sort = {}) => {
      if (enableUploader) {
        dispatch(
          actionFetchSalesReport(
            1,
            { sortingData, ...sort },
            {
              query: isMountRef.current ? query : null,
              query_data: isMountRef.current ? queryData : null,
              ...form,
            }
          )
        );
      }
      isMountRef.current = true;
    },
    [query, queryData, form, sortingData, , enableUploader, statusEnable]
  );

  useEffect(() => {
    resetData();
  }, [form]);

  const removeError = useCallback(
    (title) => {
      const temp = JSON.parse(JSON.stringify(errorData));
      temp[title] = false;
      setErrorData(temp);
    },
    [setErrorData, errorData]
  );

  const changeTextData = useCallback(
    (text, fieldName) => {
      // LogUtils.log(text, fieldName);
      let shouldRemoveError = true;
      const t = { ...form };

      t[fieldName] = text;
      setForm(t);
      sessionStorage.setItem("sales_form", t);
      shouldRemoveError && removeError(fieldName);
    },
    [removeError, form, setForm]
  );

  const checkFormValidation = useCallback(() => {
    const errors = { ...errorData };
    let required = ["location_id", "department_id", "fy_year", "quarter"];

    required.forEach((val) => {
      if (
        !form?.[val] ||
        (Array.isArray(form?.[val]) && form?.[val].length === 0)
      ) {
        errors[val] = true;
      }
    });
    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });
    return errors;
  }, [form, errorData]);

  const handlePageChange = useCallback((type) => {
    console.log("_handlePageChange", type);
    dispatch(actionSetPageSalesReportRequests(type));
  }, []);

  const queryFilter = useCallback(
    (key, value) => {
      if (enableUploader) {
        dispatch(
          actionFetchSalesReport(1, sortingData, {
            query: key == "SEARCH_TEXT" ? value : query,
            query_data: key == "FILTER_DATA" ? value : queryData,
            ...form,
          })
        );
      }
    },
    [query, queryData, enableUploader, form]
  );

  const handleFilterDataChange = useCallback(
    (value) => {
      console.log("_handleFilterDataChange", value);
      queryFilter("FILTER_DATA", value);
    },
    [queryFilter]
  );

  const handleSearchValueChange = useCallback(
    (value) => {
      console.log("_handleSearchValueChange", value);
      queryFilter("SEARCH_TEXT", value);
    },
    [queryFilter]
  );

  const handleSortOrderChange = useCallback(
    (row, order) => {
      console.log(`handleSortOrderChange key:${row} order: ${order}`);
      dispatch(actionSetPageSalesReportRequests(1));
      if (enableUploader) {
        dispatch(
          actionFetchSalesReport(
            1,
            { row, order },
            {
              query: query,
              query_data: queryData,
              ...form,
            }
          )
        );
      }
    },
    [query, queryData, enableUploader, form]
  );

  const handleRowSize = (page) => {
    console.log(page);
  };

  const handleSideToggle = useCallback(
    (data) => {
      setSidePanel((e) => !e);
    },
    [setSidePanel]
  );

  const handleConfirmToggle = useCallback(
    (data) => {
      setIsConfirm((e) => !e);
    },
    [setIsConfirm]
  );

  const configFilter = useMemo(() => {
    return [
      {
        label: "Associated HQ's",
        name: "sub_department_id",
        type: "multiSelectObject",
        custom: { extract: { id: "id", title: "name" } },
        fields: filteredSubDepartments,
      },
    ];
  }, [listData]);
  const handleCsvDownload = useCallback(() => {
    if (enableUploader) {
      serviceExportSalesReport({
        query: query,
        query_data: queryData,
        ...form,
      }).then((res) => {
        if (!res.error) {
          const data = res.data;
          window.open(data, "_blank");
        }
      });
    } else {
      SnackbarUtils.error("Please select the fields");
    }
  }, [query, queryData, enableUploader, form]);

  return {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    handleSideToggle,
    isSidePanel,
    configFilter,
    listData,
    form,
    errorData,
    changeTextData,
    isSubmitting,
    resetData,
    enableUploader,
    handleConfirmToggle,
    isConfirm,
    statusEnable,
    filteredHq,
    handleCsvDownload,
  };
};

export default useSalesReport;
