import React from "react";
import styles from "./Style.module.css";
import useTaxfilingForm from "./TaxFilingCreate.hook";
import NetSalary from "./component/TaxField/NetSalary";
import SelfProperty from "./component/TaxField/SelfProperty";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CapitalGain from "./component/TaxField/CapitalGain";
import Profession from "./component/TaxField/Profession";
import OtherSource from "./component/TaxField/OtherSource";
import Deduction from "./component/deduction/Deduction";
import TaxInfoField from "./component/TaxField/TaxInfoField";
import history from "../../../libs/history.utils";
import CustomTextField from "../../../components/FormFields/TextField/TextField.component";
import {
  ButtonBase,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import UpperTaxCard from "./component/UpperTaxCard/UpperTaxCard";
import TaxComparison from "./component/TaxField/TaxComparison";
import RentAllowence from "./component/TaxField/RentAllowence";

function TaxFilingCreate() {
  const {
    form,
    setForm,
    errorData,
    changeTextData,
    user,
    handleSubmit,
    submitToServer,
    duductionRef,
    chechRegime,
    deductionDebouncer,
    summaryData,
    isDisabled,
  } = useTaxfilingForm({});
  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <ButtonBase onClick={() => history.goBack()}>
          <ArrowBackIosIcon fontSize={"small"} />{" "}
          <span className={"capitalize"}>
            <b>Provisional Tax Filing</b>
          </span>
        </ButtonBase>
        <div className={styles.newLine} />
      </div>
      <UpperTaxCard data={user} />
      <div className={styles.plainPaper}>
        <div className={styles.heading}>Basic Information</div>
        <div className={styles.flex}>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Assessment Year:</div>
            <div className={styles.des}>{form?.as_year}</div>
          </div>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Age:</div>
            <div className={styles.des}>
              {`${user?.age} yr (Below 60 years - Regular Citizen)` || "-"}
            </div>
          </div>
        </div>
        <div className={styles.flex} style={{ marginTop: "10px" }}>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Financial Year:</div>
            <div className={styles.des}>2024-25</div>
          </div>
          <div className={styles.flex1}>
            <div className={styles.leftInfo}>Tax Payer Category:</div>
            <div className={styles.des}>{form?.payer_category}</div>
          </div>
        </div>
        <div className={styles.flex}>
          {/* <div className={styles.flex21}>
            <div className={styles.heading2}>Tax Regime</div>
            <RadioGroup
              aria-label="option"
              name="tax_regime"
              value={form?.tax_regime}
              onChange={(e) => changeTextData(e.target.value, "tax_regime")}
              row
              className={styles.radioWrap}
            >
              <FormControlLabel
                value="OLD_TAX_REGIME"
                control={<Radio />}
                label="Old Tax Regime"
              />
              <FormControlLabel
                value="NEW_TAX_REGIME"
                control={<Radio />}
                label="New Tax Regime"
              />
            </RadioGroup>
          </div> */}
          {/* <div className={styles.flex21}>
            <div className={styles.heading2}>City Type</div>
            <RadioGroup
              aria-label="option"
              name="city_type"
              value={form?.city_type}
              onChange={(e) => changeTextData(e.target.value, "city_type")}
              row
              className={styles.radioWrap}
            >
              <FormControlLabel
                value="METRO"
                control={<Radio />}
                label="Metro"
              />
              <FormControlLabel
                value="NON_METRO"
                control={<Radio />}
                label="Non Metro"
              />
            </RadioGroup>
          </div> */}
        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.heading}>
          Details for Income and tax calculation
        </div>

        <div className={styles.grossTotal}>
          <div className={styles.component}>Component</div>
          <div style={{ display: "flex" }}>
            <div className={styles.decKey}>Declared Value</div>
            <div className={styles.calKey}>Eligible Deduction</div>
          </div>
        </div>

        <NetSalary
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          chechRegime={chechRegime}
        />
        <RentAllowence
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          chechRegime={chechRegime}
        />
        <SelfProperty
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          chechRegime={chechRegime}
        />
        <CapitalGain
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          chechRegime={chechRegime}
        />
        <Profession
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          chechRegime={chechRegime}
        />
        <OtherSource
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          chechRegime={chechRegime}
        />
        <div className={styles.grossTotal}>
          <div className={styles.component}>Gross Total Income</div>

          <div style={{ display: "flex", justifyContent:"space-between" }}>
            <div className={styles.totalAmount}>
              ₹ {form?.gross_total_income}
            </div>
            <div className={styles.totalAmount}>
            ₹{chechRegime[form?.tax_regime]?.gross_total_income}
            </div>
          </div>

        </div>
      </div>
      <div className={styles.plainPaper}>
        <div className={styles.heading}>Details of Deduction</div>
        <Deduction
          form={form}
          errorData={errorData}
          changeTextData={changeTextData}
          duductionRef={duductionRef}
          deductionDebouncer={deductionDebouncer}
          chechRegime={chechRegime}
        />
        <div className={styles.grossTotal}>
          <div className={styles.component}>Taxable Income</div>

          <div style={{ display: "flex", justifyContent:"space-between" }}>
            <div className={styles.totalAmount}>
              ₹ {form?.taxable_income}
            </div>
            <div className={styles.totalAmount}>
            ₹{chechRegime[form?.tax_regime]?.taxable_income}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.plainPaper}>
        <div className={styles.heading}>Tax Regime Comparison</div>
        <TaxComparison
          chechRegime={chechRegime}
          form={form}
          changeTextData={changeTextData}
        />
      </div>

      <div className={styles.plainPaper}>
        <div className={styles.heading}>Summary</div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>1. Tax at Normal Rates</div>
          <div className={styles.right}>
            <div className={styles.total}>
              ₹ {summaryData?.tax_normal_rates}
            </div>
          </div>
        </div>
        <TaxInfoField form={form} summaryData={summaryData} />
        <div className={styles.mainFlex}>
          <div className={styles.left}>Total Tax before Rebate</div>
          <div className={styles.right}>
            <div className={styles.total}>
              ₹ {summaryData?.total_tax_before_rebate}
            </div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Less: Tax Rebate u/s 87A</div>
          <div className={styles.right}>
            <div className={styles.total}>
              ₹ {summaryData?.tax_rebate_us_87a}
            </div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Net Tax after Rebate</div>
          <div className={styles.right}>
            <div className={styles.total}>
              ₹ {summaryData?.net_tax_after_rebate}
            </div>
          </div>
        </div>{" "}
        <div className={styles.mainFlex}>
          <div className={styles.left}>Add: Surcharge</div>
          <div className={styles.right}>
            <div className={styles.total}>₹ {summaryData?.surcharge}</div>
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Add: Health & Education Cess</div>
          <div className={styles.right}>
            <div className={styles.total}>
              ₹ {summaryData?.health_education_cess}
            </div>
          </div>
        </div>
        <br />
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>Total Tax on Income</div>
          <div className={styles.totalAmount}>
            ₹ {summaryData?.total_tax_on_income}
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>
            TDS + TCS + MAT / AMT credit utilized
          </div>
          <div className={styles.right}>
            <CustomTextField
              style={{ background: "#ffffff" }}
              type="number"
              isError={errorData?.tds_tcs_mat_amt_credit}
              errorText={errorData?.tds_tcs_mat_amt_credit}
              // label={"Posted Manpower"}
              value={form?.tds_tcs_mat_amt_credit}
              onTextChange={(text) => {
                changeTextData(text, "tds_tcs_mat_amt_credit");
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ backgroundColor: "#F5F7FB" }}
                  >
                    ₹
                  </InputAdornment>
                ),
              }}
              // onBlur={() => {
              //   onBlurHandler("net_income");
              // }}
            />
          </div>
        </div>
        <div className={styles.mainFlex}>
          <div className={styles.left}>Self-Advance Tax Paid by Company</div>
          <div className={styles.right}>
            <CustomTextField
              disabled
              style={{ background: "#ffffff" }}
              type="number"
              isError={errorData?.advance_tax_paid}
              errorText={errorData?.advance_tax_paid}
              // label={"Posted Manpower"}
              value={form?.advance_tax_paid}
              onTextChange={(text) => {
                changeTextData(text, "advance_tax_paid");
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    style={{ backgroundColor: "#F5F7FB" }}
                  >
                    ₹
                  </InputAdornment>
                ),
              }}
              // onBlur={() => {
              //   onBlurHandler("net_income");
              // }}
            />
          </div>
        </div>
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>
            Balance Tax Payable / Refundable
          </div>
          <div className={styles.totalAmount}>
            ₹ {summaryData?.tax_payable_refundable}
          </div>
        </div>
        <div className={styles.grossTotal}>
          <div className={styles.heading2}>
            Tax applicability based on tenure
            {summaryData?.tenure && ` (${summaryData?.tenure} months )`}
          </div>
          <div className={styles.totalAmount}>
            ₹ {summaryData?.tax_applicable_on_tenure}
          </div>
        </div>
      </div>

      <div className={styles.btnWrap}>
        <div className={styles.btnInner}>
          <ButtonBase
            type={"button"}
            className={styles.createBtn21}
            onClick={() => submitToServer("draft")}
            disabled={isDisabled}
          >
            Save As Draft
          </ButtonBase>
        </div>
        <div className={styles.btnInner}>
          <ButtonBase
            disabled={isDisabled}
            type={"button"}
            className={styles.createBtn}
            onClick={handleSubmit}
          >
            SUBMIT
          </ButtonBase>
        </div>
      </div>
    </div>
  );
}

export default TaxFilingCreate;
