import React, { useCallback, useMemo } from "react";
import { ButtonBase, IconButton, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import { useSelector } from "react-redux";
import PageBox from "../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import Constants from "../../config/constants";
import StatusPill from "../../components/Status/StatusPill.component";
import CustomSelectField from "../../components/FormFields/SelectField/SelectField.component";
import useSalesTarget from "./SalesTarget.hook";
import FilterComponent from "../../components/Filter/Filter.component";
import Datatables from "../../components/Datatables/datatables";
import SalesDialog from "./component/SalesDialog/SalesDialog.view";
import ConfirmDialog from "./component/ConfirmDialog/ConfirmDialog";

const SalesTarget = ({}) => {
  const {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    handleSideToggle,
    isSidePanel,
    configFilter,
    listData,
    handleCsvDownload,
    form,
    errorData,
    changeTextData,
    isSubmitting,
    resetData,
    enableUploader,
    handleConfirmToggle,
    isConfirm,
    handleConfirm,
    statusEnable,
  } = useSalesTarget({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.sales);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "LOCATION",
        label: "LOCATION",
        sortable: false,
        render: (value, all) => <div>{all?.location?.name}</div>,
      },
      {
        key: "sub_department",
        label: "SUB DEPARTMENT",
        sortable: false,
        render: (value, all) => (
          <div>
            {all?.department?.name}/{all?.sub_department?.name}
          </div>
        ),
      },
      {
        key: "group",
        label: "GROUP",
        sortable: false,
        render: (temp, all) => <div>{all?.group}</div>,
      },
      {
        key: "fy_year",
        label: "FINANCIAL YEAR",
        sortable: false,
        render: (temp, all) => <div>{all?.fy_year}</div>,
      },
      {
        key: "quarter",
        label: "Quarter",
        sortable: false,
        render: (temp, all) => <div>{all?.quarter}</div>,
      },
      {
        key: "month1",
        label: "Month 1",
        sortable: false,
        render: (temp, all) => <div>{all?.month1}</div>,
      },
      {
        key: "month2",
        label: "Month 2",
        sortable: false,
        render: (temp, all) => <div>{all?.month2}</div>,
      },
      {
        key: "month3",
        label: "Month 3",
        sortable: false,
        render: (temp, all) => <div>{all?.month3}</div>,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        {isSidePanel && (
          <SalesDialog
            isOpen={isSidePanel}
            handleToggle={handleSideToggle}
            salesInfo={form}
            handleCsvUpload={resetData}
          />
        )}
        <ConfirmDialog
          isOpen={isConfirm}
          handleToggle={handleConfirmToggle}
          handleSubmit={handleConfirm}
          isSubmitting={isSubmitting}
        />
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Sales Target Letters</span>
            <div className={styles.newLine} />
          </div>
        </div>
        <div className={"formFlex"}>
          <div className={"formGroup"}>
            <CustomSelectField
              isError={errorData?.location_id}
              errorText={errorData?.location_id}
              label={"Location"}
              value={form?.location_id}
              handleChange={(value) => {
                changeTextData(value, "location_id");
              }}
            >
              {listData?.LOCATIONS?.map((dT) => {
                return (
                  <MenuItem value={dT?.id} key={dT?.id}>
                    {dT?.name}
                  </MenuItem>
                );
              })}
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              isError={errorData?.department_id}
              errorText={errorData?.department_id}
              label={"Department"}
              value={form?.department_id}
              handleChange={(value) => {
                changeTextData(value, "department_id");
              }}
            >
              {listData?.DEPARTMENTS?.map((dT) => {
                return (
                  <MenuItem value={dT?.id} key={dT?.id}>
                    {dT?.name}
                  </MenuItem>
                );
              })}
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              label={"Marketing Year"}
              isError={errorData?.fy_year}
              errorText={errorData?.fy_year}
              value={form?.fy_year}
              handleChange={(value) => {
                changeTextData(value, "fy_year");
              }}
            >
              {listData?.FY_YEAR?.map((item, index) => (
                <MenuItem key={`fy_claim_${index}`} value={item?.value}>
                  {item?.label?.replace("FY","MY")}
                </MenuItem>
              ))}
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              label={"Quarter"}
              isError={errorData?.quarter}
              errorText={errorData?.quarter}
              value={form?.quarter}
              handleChange={(value) => {
                changeTextData(value, "quarter");
              }}
            >
              <MenuItem value={"Q1"}>Q1</MenuItem>
              <MenuItem value={"Q2"}>Q2</MenuItem>
              <MenuItem value={"Q3"}>Q3</MenuItem>
              <MenuItem value={"Q4"}>Q4</MenuItem>
            </CustomSelectField>
          </div>
          <div className={styles.btnWrapper}>
            <ButtonBase
              disabled={statusEnable}
              type={"button"}
              onClick={handleSideToggle}
              className={statusEnable ? styles.disabledBtn : styles.createBtn}
            >
              UPLOAD FILE
            </ButtonBase>
          </div>
        </div>
        <div style={{ width: "100%" }}>
         <FilterComponent
                    is_progress={isFetching}
                    filters={configFilter}
                    handleSearchValueChange={handleSearchValueChange}
                    handleFilterDataChange={handleFilterDataChange}
                  />
        <Datatables
          {...tableData.datatable}
          {...tableData.datatableFunctions}
        />
      </div>
      </PageBox>
      {enableUploader}
      {!statusEnable && (
        <div className={styles.btnWrapper21}>
          <ButtonBase
            disabled={!enableUploader}
            type={"button"}
            onClick={handleConfirmToggle}
            className={styles.createBtn}
          >
            CONFIRM & SEND
          </ButtonBase>
        </div>
      )}
    </div>
  );
};

export default SalesTarget;
