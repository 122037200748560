import React, { useCallback, useMemo, useState } from "react";
import { ButtonBase, MenuItem } from "@material-ui/core";
import { useSelector } from "react-redux";
import PageBox from "../../components/PageBox/PageBox.component";
import styles from "./Style.module.css";
import Constants from "../../config/constants";
import CustomSelectField from "../../components/FormFields/SelectField/SelectField.component";
import useSalesReport from "./SalesReport.hook";
import FilterComponent from "../../components/Filter/Filter.component";
import Datatables from "../../components/Datatables/datatables";

const ExpandableList = ({ data, defaultVisible = 5 }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div>
      {data
        ?.slice(0, expanded ? data?.length : defaultVisible)
        ?.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      {data?.length > defaultVisible && (
        <div onClick={handleToggle} className = {styles.seeMore}>
          {expanded ? "See Less" : "See More"}
        </div>
      )}
    </div>
  );
};

const SalesReport = ({}) => {
  const {
    handlePageChange,
    handleFilterDataChange,
    handleSearchValueChange,
    handleRowSize,
    handleSortOrderChange,
    filteredHq,
    handleSideToggle,
    isSidePanel,
    configFilter,
    listData,
    handleCsvDownload,
    form,
    errorData,
    changeTextData,
    isSubmitting,
    resetData,
    enableUploader,
    handleConfirmToggle,
    isConfirm,
    handleConfirm,
    statusEnable,
  } = useSalesReport({});

  const {
    data,
    all: allData,
    currentPage,
    is_fetching: isFetching,
  } = useSelector((state) => state.sales_report);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "employee_info",
        label: "EMPLOYEE INFO",
        sortable: false,
        render: (value, all) => (
          <div>
            {all?.empoyee?.name}
            <br />
            {all?.empoyee?.emp_code}
          </div>
        ),
      },
      {
        key: "reviewer_info",
        label: "REVIEWER INFO",
        sortable: false,
        render: (value, all) => (
          <div>
            {all?.empoyee?.pms_reviewer?.name}
            <br />
            {all?.empoyee?.pms_reviewer?.code}
          </div>
        ),
      },
      {
        key: "hod_info",
        label: "HOD INFO",
        sortable: false,
        render: (temp, all) => (
          <div>
            {" "}
            {all?.empoyee?.hod?.hod_name}
            <br />
            {all?.empoyee?.hod?.hod_code}
          </div>
        ),
      },
      {
        key: "associated_hq_data",
        label: "ASSOCIATED HQ'S",
        sortable: false,
        render: (temp, all) => (
          <div className={styles.hqWrapper}>
            {" "}
            <ExpandableList
              data={all?.empoyee?.associated_hq_data?.map((val) => val?.name)}
            />
            {/* {
              all?.empoyee?.associated_hq_data
                  ?.map((val) => val?.name)
                  
               } */}
          </div>
        ),
      },
      {
        key: "is_manager",
        label: "IS MANAGER?",
        sortable: false,
        render: (temp, all) => (
          <div>{all?.empoyee?.is_manager ? "YES" : "NO"}</div>
        ),
      },
      {
        key: "month1",
        label: "Month 1",
        sortable: false,
        render: (temp, all) => <div>{all?.month1}</div>,
      },
      {
        key: "month2",
        label: "Month 2",
        sortable: false,
        render: (temp, all) => <div>{all?.month2}</div>,
      },
      {
        key: "month3",
        label: "Month 3",
        sortable: false,
        render: (temp, all) => <div>{all?.month3}</div>,
      },
      {
        key: "totoal",
        label: "TOTAL TARGET",
        sortable: false,
        render: (temp, all) => <div>{all?.totalTarget}</div>,
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: data,
      count: allData.length,
      page: currentPage,
    };

    return { datatableFunctions, datatable };
  }, [
    allData,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    handleRowSize,
    data,
    currentPage,
  ]);

  return (
    <div>
      <PageBox>
        <div className={styles.headerContainer}>
          <div>
            <span className={styles.title}>Sales Target Report</span>
            <div className={styles.newLine} />
          </div>
        </div>
        <div className={"formFlex"}>
          <div className={"formGroup"}>
            <CustomSelectField
              isError={errorData?.location_id}
              errorText={errorData?.location_id}
              label={"Location"}
              value={form?.location_id}
              handleChange={(value) => {
                changeTextData(value, "location_id");
              }}
            >
              {listData?.LOCATIONS?.map((dT) => {
                return (
                  <MenuItem value={dT?.id} key={dT?.id}>
                    {dT?.name}
                  </MenuItem>
                );
              })}
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              isError={errorData?.department_id}
              errorText={errorData?.department_id}
              label={"Department"}
              value={form?.department_id}
              handleChange={(value) => {
                changeTextData(value, "department_id");
              }}
            >
              {listData?.DEPARTMENTS?.map((dT) => {
                return (
                  <MenuItem value={dT?.id} key={dT?.id}>
                    {dT?.name}
                  </MenuItem>
                );
              })}
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              label={"Marketing Year"}
              isError={errorData?.fy_year}
              errorText={errorData?.fy_year}
              value={form?.fy_year}
              handleChange={(value) => {
                changeTextData(value, "fy_year");
              }}
            >
              {listData?.FY_YEAR?.map((item, index) => (
                <MenuItem key={`fy_claim_${index}`} value={item?.value}>
                  {item?.label?.replace("FY", "MY")}
                </MenuItem>
              ))}
            </CustomSelectField>
          </div>
          <div className={"formGroup"}>
            <CustomSelectField
              label={"Quarter"}
              isError={errorData?.quarter}
              errorText={errorData?.quarter}
              value={form?.quarter}
              handleChange={(value) => {
                changeTextData(value, "quarter");
              }}
            >
              <MenuItem value={"Q1"}>Q1</MenuItem>
              <MenuItem value={"Q2"}>Q2</MenuItem>
              <MenuItem value={"Q3"}>Q3</MenuItem>
              <MenuItem value={"Q4"}>Q4</MenuItem>
            </CustomSelectField>
          </div>
          <div className={styles.btnWrapper}>
          <div className={styles.rightFlex}>
            <ButtonBase className={styles.download} onClick={handleCsvDownload}>
              DOWNLOAD
            </ButtonBase>
          </div>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className={styles.hqWrap}>
            Showing result for HQ :
            {filteredHq?.length > 0 && filteredHq?.map((s) => s)?.join(" ,  ")}
          </div>
          <FilterComponent
            is_progress={isFetching}
            filters={configFilter}
            handleSearchValueChange={handleSearchValueChange}
            handleFilterDataChange={handleFilterDataChange}
          />
          <Datatables
            {...tableData.datatable}
            {...tableData.datatableFunctions}
          />
        </div>
      </PageBox>
    </div>
  );
};

export default SalesReport;
