import React, { useCallback, useMemo } from "react";
import styles from "./Style.module.css";
import useTaxSubmissionMasterHook from "./TaxSubmissionmaster.hook";
import CustomSelectField from "../../components/FormFields/SelectField/SelectField.component";
import { ButtonBase, makeStyles, MenuItem } from "@material-ui/core";
import classNames from "classnames";
import File from "../../components/FileComponent/FileComponent.component";
import csx from "classnames";
import MuiStyle from "../../libs/MuiStyle";
import CustomSwitch from "../../components/FormFields/CustomSwitch";
import DataTables from "../../Datatables/Datatable.table";
import Constants from "../../config/constants";
import CustomDatePicker from "../../components/FormFields/DatePicker/CustomDatePicker";
import UploadCsvDialog from "./components/UploadCsv/UploadCsvDialog.view";

const useStyle = makeStyles(MuiStyle);

const TaxSubmissionMasterView = () => {
  const {
    form,
    setForm,
    errorData,
    changeTextData,
    onBlurHandler,
    handleReset,
    isFormValid,
    handleSubmit,
    handleChange,
    handlePageChange,
    handleSortOrderChange,
    handleViewDetails,
    isSubmitting,
    toggleCsvDialog,
    isCsvDialog,
    handleCsvUpload,
    currentData,
    data,
    currentPage,
  } = useTaxSubmissionMasterHook();
  const classes = useStyle();

  const renderFirstCell = useCallback((obj) => {
    if (obj) {
      return (
        <div className={styles.firstCellFlex}>
          <div className={classNames(styles.firstCellInfo, "openSans")}>
            <span className={styles.productName}>{obj?.employee_name}</span>{" "}
            <br />
            <span>{obj?.employee_code}</span>
          </div>
        </div>
      );
    }
    return null;
  }, []);

  const tableStructure = useMemo(() => {
    return [
      {
        key: "name",
        label: "EMPLOYEE NAME",
        sortable: true,
        render: (value, all) => <div>{renderFirstCell(all)}</div>,
      },
      {
        key: "garde_code",
        label: "GRADE",
        sortable: false,
        render: (temp, all) => (
          <div className={styles.captialize}>
            {all?.grade}
            {/* / {all?.cadre || ""} */}
          </div>
        ),
      },
      {
        key: "location",
        label: "LOCATION",
        sortable: true,
        render: (temp, all) => <div>{all?.location}</div>,
      },

      {
        key: "designation",
        label: "DESIGNATION",
        sortable: false,
        render: (temp, all) => <div>{all?.designation}</div>,
      },
      {
        key: "attachment",
        label: "DEPT.",
        sortable: false,
        render: (temp, all) => <div>{all?.department}</div>,
      },
      {
        key: "attachment",
        label: "CTC",
        sortable: false,
        render: (temp, all) => <div>{all?.ctc && `₹${all?.ctc}`}</div>,
      },
      {
        key: "attachment",
        label: "TAX AMOUNT",
        sortable: false,
        render: (temp, all) => <div>₹{all?.tax_by_company}</div>,
      },
    ];
  }, [isSubmitting, handleViewDetails]);

  const tableData = useMemo(() => {
    const datatableFunctions = {
      onSortOrderChange: handleSortOrderChange,
      onPageChange: handlePageChange,
      //   onRowSizeChange: handleRowSize,
    };

    const datatable = {
      ...Constants.DATATABLE_PROPERTIES,
      columns: tableStructure,
      data: currentData,
      count: data.length,
      page: currentPage - 1,
      rowsPerPage: 10,
      allRowSelected: false,
      showSelection: false,
    };

    return { datatableFunctions, datatable };
  }, [
    data,
    tableStructure,
    handleSortOrderChange,
    handlePageChange,
    currentData,
    currentPage,
  ]);

  return (
    <>
      <div className={styles.plainPaper}>
        <div className={styles.mainText}>
          <div className={styles.headerContainer}>
            <div>
              <span className={styles.title}>Tax Submission Master</span>
              <div className={styles.newLine} />
            </div>
          </div>
        </div>

        {/* <div className={styles.dFlex}>
          <div className={"formGroup"}>
            <CustomSelectField
              isError={errorData?.assessment_year}
              errorText={errorData?.assessment_year}
              label={"Select Assessment Year"}
              value={form?.assessment_year}
              handleChange={(value) => {
                changeTextData(value, "assessment_year");
              }}
            >
              <MenuItem value="CLEAR">2023 - 2024</MenuItem>
              <MenuItem value="CLEAR">2024 - 2025</MenuItem>
            </CustomSelectField>
          </div>

          <div className={"formGroup"}>
            <File
              max_size={5 * 1024 * 1024}
              type={["pdf"]}
              fullWidth={true}
              name="document"
              accept={"application/pdf"}
              label={"Upload File"}
              //   default_image={document ? document : null}
              // user_image={form?.image}
              //   error={error}
              title={'Upload File'}
              value={document}
              // handleChange={this._handleFileChange}
              placeholder={"Upload File"}
              //   link={EMPLOYEE_INDUCTION?.document}
              onChange={(file) => {
                if (file) {
                  changeTextData(file, "document")
                }
              }}
            />
          </div>
          <div className={"formGroup"}>
            <ButtonBase
              onClick={handleSubmit}
              // disabled={!(document || coverImage) || isSubmitting}
              className={csx(classes.createBtn, styles.btn)}
              id={styles.mobileResponsiveWeb}
            >
              Upload
            </ButtonBase>
          </div>
          <div className={"formGroup"}></div>
        </div> */}

        <hr className={styles.horizontalDivider} />

        <div className={styles.dFlex2} id={styles.mobileResponsive}>
          <div className={styles.container}>
            <div className={"headerFlex"}>
              <h4 className={"infoTitle"}>
                <div className={styles.title}>Provisional Form Filing</div>
              </h4>
            </div>

            <div className={styles.dFlexGroup2}>
              <CustomSwitch
                //   disabled={isDisabled}
                value={form?.is_provisional_form_filing}
                handleChange={() => {
                  changeTextData(
                    !form?.is_provisional_form_filing,
                    "is_provisional_form_filing",
                    "PROVISIONAL"
                  );
                }}
                label={`Active`}
              />
            </div>

            {form?.is_provisional_form_filing && (
              <div className={"formFlex"}>
                <>
                  <div className={"formGroup"}>
                    <CustomDatePicker
                      clearable
                      label={"Start Date"}
                      onChange={(date) => {
                        changeTextData(date, "pro_start_date", "PROVISIONAL");
                      }}
                      value={form?.pro_start_date}
                      isError={errorData?.pro_start_date}
                    />
                  </div>
                  <div className={"formGroup"}>
                    <CustomDatePicker
                      clearable
                      label={"End Date"}
                      onChange={(date) => {
                        changeTextData(date, "pro_end_date", "PROVISIONAL");
                      }}
                      value={form?.pro_end_date}
                      isError={errorData?.pro_end_date}
                    />
                  </div>
                </>
              </div>
            )}
          </div>

          <div className={styles.container}>
            <div className={"headerFlex"}>
              <h4 className={"infoTitle"}>
                <div className={styles.title}>Final Form Filing</div>
              </h4>
            </div>

            <div className={styles.dFlexGroup2}>
              <CustomSwitch
                //   disabled={isDisabled}
                value={form?.is_final_form_filing}
                handleChange={() => {
                  changeTextData(
                    !form?.is_final_form_filing,
                    "is_final_form_filing",
                    "FINAL"
                  );
                }}
                label={`Active`}
              />
            </div>
            {form?.is_final_form_filing && (
              <div className={"formFlex"}>
                <div className={"formGroup"}>
                  <CustomDatePicker
                    clearable
                    label={"Start Date"}
                    // minDate={new Date()}
                    onChange={(date) => {
                      changeTextData(date, "final_start_date", "FINAL");
                    }}
                    value={form?.final_start_date}
                    isError={errorData?.final_start_date}
                  />
                </div>
                <div className={"formGroup"}>
                  <CustomDatePicker
                      clearable
                      label={"End Date"}
                      onChange={(date) => {
                        changeTextData(date, "final_end_date", "FINAL");
                      }}
                      value={form?.final_end_date}
                      isError={errorData?.final_end_date}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={styles.dFlex2} id={styles.mobileResponsive}></div>
      </div>

      <div className={styles.plainPaper}>
        <div className={styles.dFlex}>
          <div>
            <div className={styles.subTitle}>
              Select assessment year to upload the tax file
            </div>
          </div>
          <div>
            <ButtonBase
              onClick={toggleCsvDialog}
              className={csx(classes.createBtn, styles.btn)}
              id={styles.mobileResponsiveWeb}
            >
              Upload
            </ButtonBase>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <DataTables
            {...tableData.datatable}
            {...tableData.datatableFunctions}
          />
        </div>
        <UploadCsvDialog
          isOpen={isCsvDialog}
          handleToggle={toggleCsvDialog}
          handleCsvUpload={handleCsvUpload}
        />
      </div>
    </>
  );
};

export default TaxSubmissionMasterView;
