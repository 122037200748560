import React, { useMemo, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Button, Grid, Typography, Box, ButtonBase, ButtonGroup, Chip, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Styles from './Style.module.css';
import PageBox from '../../../components/PageBox/PageBox.component';
import useInterviewCalendarHook from './InterviewCalendar.hook';
import history from '../../../libs/history.utils';
import DetailDialog from './components/DetailDialog/DetailDialog.view';
import RouteName from '../../../routes/Route.name';
import OverlappingEventsDialog from './OverLappingDialog.view';

const localizer = momentLocalizer(moment);

const CustomDayHeader = ({ date }) => {
    return (
        <div className={Styles.dayHeader}>
            {moment(date).format("D/M ddd")}
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    f2fChip: {
        backgroundColor: '#F4881B !important',
        width: "12px !important",
        height: "12px !important",
        marginRight: "3px !important",
    },
    onlineChip: {
        backgroundColor: '#503EAF !important',
        width: "12px !important",
        height: "12px !important",
        marginRight: "3px !important",
    }
}));

const InterviewCalendarView = () => {
    const {
        eventStyleGetter,
        view,
        currentDate,
        setCurrentDate,
        setView,
        activeClass,
        handleCloseDialog,
        eventId,
        handleEventClick,
        isDialogOpen,
        isCurrentRoute,
        events,
        combinedEventPropGetter,
        overlappingEvents,
        overlappingDialog,
        handleCloseOperlappingDialog,
        processedEvents,
        CustomEvent
    } = useInterviewCalendarHook();

    const classes = useStyles();


    const CustomToolbar = ({ label, onNavigate, onView, date, view }) => {
        const formatLabel = () => {
            if (view === 'week') {
                const startOfWeek = moment(date).startOf('week');
                const endOfWeek = moment(date).endOf('week');
                return `${startOfWeek.format('MMMM D')} - ${endOfWeek.format('MMMM D')}`;
            }
            return moment(date).format('dddd, MMM D');
        };

        const handleViewChange = (newView) => {
            if (newView === 'day') {
                setCurrentDate(new Date())
            }
            onView(newView);
        };
        return (
            <div className={Styles.toolWrapper}>
                <div className={Styles.btnWrap}>
                    <div>
                        <ButtonGroup variant="outlined"
                            size="large"
                            aria-label="Medium-sized button group">
                            <Button
                                color="primary"
                                variant="outlined"
                                size="large"
                                onClick={() => handleViewChange("week")}
                                style={view === "week" ? activeClass : {}} >
                                <Typography variant="body1">WEEK</Typography>
                            </Button>

                            <Button
                                color="primary"
                                variant="outlined"
                                size="large"
                                onClick={() => handleViewChange("day")}
                                style={view === "day" ? activeClass : {}}>
                                <Typography variant="body1">DAY</Typography>
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className={Styles.upperWrap}>
                        <div className={Styles.btnCont}>
                            <Typography variant="h5">{formatLabel()}</Typography>
                            <ButtonBase>
                                <ArrowBackIosIcon
                                    onClick={() => onNavigate("PREV")}
                                    fontSize={"small"}
                                    className={Styles.navIcon}
                                />
                            </ButtonBase>
                            <ButtonBase >
                                <ArrowForwardIosIcon
                                    onClick={() => onNavigate("NEXT")}
                                    fontSize={"small"}
                                    className={Styles.navIcon}
                                />
                            </ButtonBase>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <PageBox>
            <div className={Styles.headerContainer}>
                <div>
                    <ButtonBase onClick={() => history.goBack()}>
                        <ArrowBackIosIcon color={"#919BB0"} fontSize={"small"} />
                        <span className={"capitalize"}>
                            <b>{isCurrentRoute(RouteName.INTERVIEW_CALENDAR) ? "Interview Calendar" : "Overall Interview Calendar"}</b>
                        </span>
                    </ButtonBase>
                    <div className={Styles.newLine} />
                </div>
                <div className={Styles.btnWrap}>
                    <div>
                        <Chip classes={{ root: classes.f2fChip }} /> F2F
                    </div>
                    <div>
                        <Chip classes={{ root: classes.onlineChip }} /> Online
                    </div>
                </div>
            </div>
            <div className={Styles.detailWrap}>
                <Calendar
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    defaultView={view}
                    views={['day', 'week']}
                    events={processedEvents}
                    eventPropGetter={combinedEventPropGetter}
                    popup
                    onView={(view) => setView(view)}
                    components={{
                        event: CustomEvent,
                        toolbar: (props) => (
                            <CustomToolbar {...props} date={currentDate} view={view} />
                        ),
                        week: {
                            header: ({ date, localizer }) => localizer.format(date, 'D/M ddd')
                        },
                        day: {
                            header: CustomDayHeader
                        }
                    }}
                    formats={{
                        dayFormat: (date, culture, localizer) =>
                            localizer.format(date, 'ddd MM/DD', culture),
                    }}
                    step={30}
                    eventLimit={2}
                    timeslots={1}
                    date={currentDate}
                    showMultiDayTimes={true}
                    onNavigate={(date) => setCurrentDate(date)}
                    onSelectEvent={handleEventClick}
                />
                <DetailDialog
                    open={isDialogOpen}
                    onClose={handleCloseDialog}
                    eventId={eventId}
                />
                <OverlappingEventsDialog
                    open={overlappingDialog}
                    onClose={() => handleCloseOperlappingDialog()}
                    events={overlappingEvents}
                    handleEventClick={handleEventClick}
                />
            </div>
        </PageBox>
    );
};

export default InterviewCalendarView;