import { useEffect, useState, useMemo, useCallback, memo } from "react";
import { serviceGetIntervieScheduleList } from "../../../services/InterviewSchedule.service";
import { alpha } from "@material-ui/core";
import { useSelector } from "react-redux";
import historyUtils from "../../../libs/history.utils";
import RouteName from "../../../routes/Route.name";
import { serviceGetJobOpeningInterviewers } from "../../../services/JobOpenings.service";
import { useLocation } from "react-router";
import moment from "moment";
import Styles from "./Style.module.css";

const useInterviewCalendarHook = () => {
  const [view, setView] = useState("week");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [eventId, setEventId] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const [panelId, setPanelId] = useState([]);
  const location = useLocation();
  const { jobId } = location.state || {};
  const [overlappingDialog, setOverlapingDialog] = useState(false);
  const [overlappingEvents, setOverlappingEvents] = useState([]);

  const activeClass = useMemo(
    () => ({
      background: "#E8F0FE 0% 0% no-repeat padding-box",
    }),
    []
  );

  const isCurrentRoute = useCallback((routeName) => {
    return historyUtils.location.pathname === routeName;
  }, []);

  useEffect(() => {
    if (isCurrentRoute(RouteName.OVERALL_INTERVIEW_CALENDAR)) {
      serviceGetJobOpeningInterviewers({ id: jobId }).then((res) => {
        if (!res?.error) {
          const ids = res.data?.map((res) => res.id);
          setPanelId(ids);
        }
      });
    } else if (isCurrentRoute(RouteName.INTERVIEW_CALENDAR)) {
      setPanelId([user.user_id]);
    }
  }, [jobId, isCurrentRoute]);

  const mapToEvents = useCallback((data) => {
    return data.map((item) => ({
      id: item._id,
      // title: item.candidate.name.length > 10
      // ? `${item.candidate.name.substring(0, 10)}...`
      // : item.candidate.name,
      title: item.candidate.name,
      fullName: item.candidate.name,
      start: new Date(item.interview_date),
      end: new Date(new Date(item.interview_date).getTime() + 30 * 60 * 1000),
      mode: item.mode,
      venue: item.venue,
      interviewer: item.interviewer,
      candidate: item.candidate.name,
      interviewDateText: item.interviewDateText,
      is_feedback: item.is_feedback,
    }));
  }, []);

  useEffect(() => {
    if (panelId.length > 0) {
      serviceGetIntervieScheduleList({
        index: 1,
        interviewer_ids: panelId,
      })
        .then((res) => {
          if (!res?.error) {
            setEvents(mapToEvents(res.data));
          }
        })
        .catch((err) => {
          console.error("Error fetching interview schedule list:", err);
        });
    }
  }, [isCurrentRoute, mapToEvents, panelId]);

  const handleToggle = useCallback((data) => {
    const id = data?.id;
    setEventId(id || null);
    setDialogOpen((prev) => !prev);
  }, []);

  const handleEventClick = useCallback(
    (slotInfo) => {
      handleToggle(slotInfo);
    },
    [handleToggle]
  );

  const eventStyleGetter = useCallback(
    (event) => {
      let textColor;
      if (event.is_feedback) {
        textColor = "#239611";
      } else {
        textColor = event.mode === "IN_PERSON" ? "#F4881B" : "#503EAF";
      }
      return {
        style: {
          background: alpha(textColor, 0.2),
          color: textColor,
          padding: "4px",
          border: "none",
          borderRadius: view === "day" ? "0" : "4px",
        },
      };
    },
    [view]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);
  const handleCloseOperlappingDialog = useCallback(() => {
    setOverlapingDialog((s) => !s);
  }, []);

  const processOverlappingEvents = useCallback((events) => {
    const groupedEvents = groupEventsByTime(events);

    return groupedEvents.map((group) => {
      const firstEvent = group[0];
      const lastEvent = group[group.length - 1];

      return {
        ...firstEvent,
        start: group.start,
        end: group.end,
        overlapCount: group.length,
        overlappingGroup: group,
        title:
          group.length > 1
            ? `${firstEvent.title} +${group.length - 1} more`
            : firstEvent.title,
      };
    });
  }, []);

  const CustomEvent = ({ event }) => {
    const handleMoreClick = () => {
      setOverlappingEvents(event.overlappingGroup || []);
      setOverlapingDialog(true);
    };

    return (
      <div className={Styles.dFlex}>
        <span className="rbc-event-label">{event.title.split(" +")[0]}</span>
        {event.overlapCount > 1 && (
          <span
            className={Styles.moreBtn}
            onClick={(e) => {
              e.stopPropagation();
              handleMoreClick();
            }}
          >
            {` +${event.overlapCount - 1} more`}
          </span>
        )}
      </div>
    );
  }

  const groupEventsByTime = useCallback((events) => {
    if (!events || events.length === 0) return [];

    const sortedEvents = [...events].sort((a, b) => a.start - b.start);
    const groupedEvents = [];

    sortedEvents.forEach((event) => {
      let added = false;

      for (const group of groupedEvents) {
        const firstEvent = group[0];
        const lastEvent = group[group.length - 1];

        if (
          moment(event.start).isBetween(
            firstEvent.start,
            lastEvent.end,
            null,
            "[)"
          ) ||
          moment(event.end).isBetween(
            firstEvent.start,
            lastEvent.end,
            null,
            "(]"
          ) ||
          moment(firstEvent.start).isBetween(
            event.start,
            event.end,
            null,
            "[)"
          ) ||
          moment(lastEvent.end).isBetween(event.start, event.end, null, "(]")
        ) {
          group.push(event);

          group.start = moment
            .min(moment(group.start), moment(event.start))
            .toDate();
          group.end = moment.max(moment(group.end), moment(event.end)).toDate();

          added = true;
          break;
        }
      }

      if (!added) {
        groupedEvents.push([event]);
        groupedEvents[groupedEvents.length - 1].start = event.start;
        groupedEvents[groupedEvents.length - 1].end = event.end;
      }
    });

    return groupedEvents;
  }, []);

  const processedEvents = useMemo(
    () => processOverlappingEvents(events),
    [events, processOverlappingEvents]
  );

  const combinedEventPropGetter = (event, start, end, isSelected) => {
    const existingStyles = eventStyleGetter(event, start, end, isSelected);

    if (event.isHidden) {
      return {
        ...existingStyles,
        style: {
          ...existingStyles?.style,
          display: "none",
          left: "0% important",
        },
      };
    }

    if (event.fullWidth) {
      return {
        ...existingStyles,
        style: {
          ...existingStyles?.style,
          width: "calc(100% - 10px)",
          minWidth: "calc(100%)",
          position: "absolute",
          left: "0",
          zIndex: 1,
        },
      };
    }

    return {
      ...existingStyles,
      style: {
        ...existingStyles?.style,
      },
    };
  };

  return {
    eventStyleGetter,
    view,
    currentDate,
    setCurrentDate,
    setView,
    events,
    isDialogOpen,
    handleEventClick,
    activeClass,
    handleCloseDialog,
    eventId,
    isCurrentRoute,
    processedEvents,
    processOverlappingEvents,
    overlappingDialog,
    handleCloseOperlappingDialog,
    combinedEventPropGetter,
    overlappingEvents,
    CustomEvent,
  };
};

export default useInterviewCalendarHook;
