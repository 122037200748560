import React from "react";
import { Dialog, Avatar, Divider, CircularProgress, ButtonBase, makeStyles, Slide } from "@material-ui/core";
import Styles from "./Style.module.css";
import { Close } from "@material-ui/icons";
import useInterviewDetailDialogHook from "./DetailDialog.hook";

const useStyles = makeStyles((theme) => ({
    closeBtn: {
        position: "absolute",
        right: "10px",
        top: "10px",
    },
    divider: {
        margin: "16px 0",
    }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DetailDialog = ({ open, onClose, eventId }) => {
    const classes = useStyles();
    const { isLoading, interviewDetail,handleJobDetails,handleCandidateDetails } = useInterviewDetailDialogHook({ eventId });

    return (
        <Dialog
            open={open}
            onClose={onClose} aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description" maxWidth="sm"
            keepMounted
            BackdropProps={{
                style: { backgroundColor: "rgba(0, 0, 0, 0.3)" },
              }}
            TransitionComponent={Transition}
            fullWidth>

            <div className={Styles.resetPasswordWrapper}>
                {
                    isLoading ? <CircularProgress fontSize="small" /> : (
                        <>
                            <div className={Styles.upperWrap}>
                                <div>
                                    <span className={Styles.title}>Interview Details</span>
                                    <div className={Styles.newLine} />
                                </div>

                                <ButtonBase classes={{ root: classes.closeBtn }}
                                    onClick={onClose}>
                                    <Close />
                                </ButtonBase>
                            </div>
                            <div className={Styles.container}>
                                <div div className={Styles.headingWrapper}>
                                    <div className={Styles.key}>Candidate Name:</div> 
                                    <div className={Styles.link} onClick={()=>handleCandidateDetails(interviewDetail?.candidate)}>{interviewDetail?.candidate?.name}
                                    </div>
                                </div>
                                <div className={Styles.headingWrapper}>
                                    <div className={Styles.key}>PRC:</div>
                                    <div className={Styles.link} onClick={()=>handleJobDetails(interviewDetail?.job)}>
                                        {interviewDetail?.job?.code}
                                    </div>
                                </div>
                                <div className={Styles.headingWrapper}>
                                    <div className={Styles.key}>Designation/Department:</div><div className={Styles.value}> {interviewDetail?.job?.designation} / {interviewDetail?.job?.department}</div>
                                </div>
                                <div className={Styles.headingWrapper}>
                                    <div className={Styles.key}>Schedule: </div><div className={Styles.value}>{interviewDetail?.interviewDateText}</div>
                                </div>
                                <div className={Styles.headingWrapper}>
                                    <div className={Styles.key}>Venue/Mode: </div>
                                    <div className={Styles.value}>
                                        {interviewDetail?.venue} | 
                                        {interviewDetail?.mode === "VIRTUAL" || interviewDetail?.mode === "TELEPHONIC" ? " Online" : " F2F"}
                                    </div>
                                </div>

                                {(interviewDetail?.mode === "VIRTUAL" || interviewDetail?.mode === "TELEPHONIC") && interviewDetail?.link && (
                                    <div className={Styles.headingWrapper}>
                                        <div className={Styles.key}>Link: </div>
                                        <div>
                                            <a className={Styles.link} href={interviewDetail?.link} target="_blank" rel="noopener noreferrer">View Link</a>
                                        </div>
                                    </div>
                                )}
                                <Divider classes={{ root: classes.divider }} />
                                <div className={Styles.subHeading} >
                                    Interviewer Details
                                </div>

                                <div className={Styles.intContainer}>
                                    {interviewDetail?.interviewers?.map((val, index) => (
                                        <div className={Styles.interviewerWrapper}
                                            key={index}
                                        >
                                            <Avatar style={{ marginRight: "8px" }} alt="interviewer" src={val?.image}>
                                            </Avatar>
                                            <div>
                                                <div className={Styles.intName}>{val?.name}</div>
                                                <div>{val?.code}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </Dialog >
    );
};

export default DetailDialog;
